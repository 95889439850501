.efex-grey-button {
    background-color: #7b7b7b;
    border-radius: 5px;
    font-size: 14px;
    font-family: Roboto;
    color: #fff;
    border: none;
    padding-bottom: 5px;
    font-weight: 500;
    padding-top: 5px;
    height: 35px;
    min-width: 80px;
    cursor: pointer;
    margin-top: 8px;

    span {
        margin-right: 10px;
    }
}