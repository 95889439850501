* {
  font-family: 'Roboto', sans-serif;
}
#root {
  height: 100%;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #f0f2f5;
}
p {
  margin: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-layout-header {
  background-color: var(--green);
  // border-bottom: 2px solid var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  margin-bottom: 20px;
}

.ant-layout {
  align-items: center;
  height: auto;
  width: 100%;

  .ant-breadcrumb {
    margin-bottom: 16px;
    a:hover {
      color: var(--green);
    }
    .anticon {
      margin-right: 4px;
    }
  }
}

.ant-layout-header .ant-typography {
  color: var(--white);
}

.container {
  max-width: 1320px;

  @media (max-width: 1280px) {
    max-width: 1080px;
  }
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  margin: 0;
  color: var(--green);
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ant-card {
  padding: 12px;
  border-radius: 16px;
  /* width: 80%; */
  /* margin: 40px 0 0; */
}
.ant-card-body {
  padding: 0;
}
.ant-card-actions {
  li {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--green);
  color: var(--green);
}
.ant-btn-primary,
.ant-btn-primary:active {
  background: var(--green);
  background-color: var(--green);
  outline: none;
  border: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  /* background: var(--green); */
  color: var(--white);
  background-color: var(--green-light);
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover {
  border-color: var(--green) !important;
  background-color: var(--green) !important;
}
.ant-checkbox-checked::after {
  border-color: var(--green) !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--green) !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: var(--green);
  border-color: var(--green);
}
.ant-steps-item-icon .ant-steps-icon {
  color: var(--green);
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--green);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--green);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--green);
}

.ant-tabs-tab:hover {
  color: var(--green);
}
.ant-tabs-ink-bar {
  background-color: var(--green);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--green);
}

.efex-table {
  width: 100%;
  margin-top: 16px;
  .feature {
    color: #5a5a5a;
    font-weight: 600;
    margin-right: 5px;
  }

  .ant-table {
    width: 100%;
    padding: 0;
    // padding-bottom: 10px;
  }

  .ant-table-thead .ant-table-cell {
    background-color: white;
    color: #5a5a5a;
    padding: 0 10px;
    font-size: 12px;
  }

  .ant-table-tbody .ant-table-cell {
    background-color: #fafafa;
    padding: 10px;
    font-size: 14px;

    .feature {
      color: #5a5a5a;
      font-size: 10px;
    }
  }
}

.delete-btn {
  font-size: 18px;
  margin-left: 8px;
}
.delete-btn:hover {
  color: #c50404;
  cursor: pointer;
}
.ant-upload,
.anticon-download {
  font-size: 18px;
}
.ant-upload:hover,
.anticon-download:hover {
  color: var(--green);
  cursor: pointer;
}

.efex-currency-field {
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
