.ant-layout.base-layout {
  align-items: flex-start;

  .ant-layout-sider {
    height: 100vh;

    .logo {
      background-color: white;
      border-bottom: 1px solid var(--green);
      margin: 0;
      padding: 16px;
      padding-left: 24px;
      color: var(--green);
      font-size: 20px;
    }
  }
  .ant-layout-header {
    padding: 0 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .anticon.trigger {
      font-size: 24px;
    }
  }
  .ant-menu-item-selected {
    color: var(--green);
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--green);
  }
  .ant-menu-title-content,
  .ant-menu-inline-collapsed-noicon,
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: var(--green);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6ffed;
  }
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
