.ant-row.row-empresa-endereco {
    flex-wrap: initial;

    .ant-col {
        width: 100%;
    }
}
.partner-item {
    background-color: white;
    border-radius: 16px !important;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
}

.subtitle {
    color: var(--green);
    font-weight: 600;
}