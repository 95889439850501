.box-check {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
}

.partner-card {
    cursor: pointer;
    position: relative;
    width: 100% !important;
    margin-bottom: 10px;
}
.partner-card-disabled {
    cursor: not-allowed;
}

.partner-card .anticon-delete {
    top: 50%;
    right: 12px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 200;
    position: absolute;
    transform: translateY(-50%);
    transition: 0.2s all ease-in-out;
}
.partner-card .anticon-delete:hover {
    opacity: .6;
    transition: 0.2s all ease-in-out;
} 