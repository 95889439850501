.single {
    width: 100%;
    padding: 8px;
}
.doc-list {
    margin-top: 16px;
    
    .doc-item {
        margin-right: 4px;
        p {
            margin-right: 8px;
        }
    }    
}
.exchange-values-card {
    height: "100%";
    margin-bottom: "16px";

    .ant-card-body {
        & > .ant-col {
            margin-bottom: 16px;
        }
    }
}
