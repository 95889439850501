
.feedback-modal {
    .ant-modal-body {
        padding: 16px;

        .ant-form-item {
            margin: 0;
        }
    }
}
