.user {
    padding: 0 8px;
    align-items: baseline;
    .legal-person {
        width: 100%;
    }
    .update-register-btn {
        margin-right: 16px;
    }
    .ant-card {
        width: 100%;
    }
}