.exchange-payments {
    width: 100%;
    padding: 8px;

    .ant-card {
        padding: 0;

        .ant-tabs-nav{
            padding: 0 8px;
        }
    }
}