.contact-receive-card {
  width: 100%;
  cursor: pointer;

  .card-header {
    padding-top: 5px;

    > div {
      font-weight: 500;
      color: #5a5a5a;
    }

    .icon-type {
      border-radius: 50%;
      background-color: #fafafa;
      border: 1px solid #eaeaea;
      padding: 10px 12px;

      svg {
        width: 25px;
        height: 25px;
        fill: #aaaaaa !important;
      }
    }

    .ant-divider {
      margin: 10px 0 20px 0;
    }

    .ant-typography {
      font-size: 18px !important;
    }

    .description {
      font-size: 16px;
    }
  }

  .card-body {
    .feature {
      color: #5a5a5a;
      font-weight: 600;
      margin-right: 5px;
    }

    .ant-table {
      width: 100%;
      padding: 0;
    }

    .ant-table-thead .ant-table-cell {
      background-color: transparent;
      color: #5a5a5a;
      padding: 0 10px;
      font-size: 12px;
    }

    .ant-table-tbody .ant-table-cell {
      background-color: #fafafa;
      padding: 10px;
      font-size: 14px;

      .feature {
        color: #5a5a5a;
        font-size: 10px;
      }
    }
  }
}

.company-card.disabled:hover {
  cursor: not-allowed;
}
