.company-id-content {
    padding: 0 8px;
    align-items: baseline;

    .legal-person {
        width: 100%;
    }

    .update-register-btn {
        margin-right: 16px;
    }

    .ant-card {
        width: 100%;

        .ant-tabs-tab:hover {
            color: var(--green);
        }

        .ant-tabs-ink-bar {
            background-color: var(--green);
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs-tab-btn:focus,
        .ant-tabs-tab-remove:focus,
        .ant-tabs-tab-btn:active,
        .ant-tabs-tab-remove:active {
            color: var(--green);
        }
    }
}

.checkbox-group-vertical .ant-checkbox-wrapper {
    display: flex;
}