.single {
    width: 100%;
    padding: 8px;
}

.exchange-values-card {
    height: "100%";
    margin-bottom: "16px";

    .ant-card-body {
        & > .ant-col {
            margin-bottom: 16px;
        }
    }
}

.box{
    max-width: 70%;
    // margin-top: 20px;
    height: 100px;

    textarea{
        height: 100%;
    }
}
