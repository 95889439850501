.content-companies.ant-layout-content{
    padding: 0 8px;
    align-items: baseline;

    .ant-card{
        width: 100%;
    }
}

.copy-to-clipboard{
    margin-left: 8px;
    color: #1890ff;

    &:hover {
        color: var(--blue);
        cursor: pointer;
    }
    &:focus {
        color: var(--green);
    }
}