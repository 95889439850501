.single {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
}

.feature {
    color: #5a5a5a !important;
    font-weight: 600;
    margin-right: 5px;
}

.label-value > label {
    color: #5a5a5a !important;
    font-weight: 600;
    margin-right: 5px;
}

.exchange-values-card {
    height: "100%";
    margin-bottom: "16px";

    .ant-card-body {
        & > .ant-col {
            margin-bottom: 16px;
        }
    }
}
