.content-upload {
  .download-btn {
    margin-right: 4px;
  }

  .send-to-sign {
    margin-left: 16px;
  }

  .doc-company-card {
    margin-top: 8px;

    .ant-row.ant-form-item {
      margin: 0;
    }

    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-col {

        .doc-required,
        .doc-filename {
          margin: 0;
        }
      }
    }
  }

  .responsaveis {
    .ant-card.ant-card-bordered {
      margin-top: 8px;
    }

    .ant-row.ant-form-item {
      margin-bottom: 8px;
    }

    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }
  }

  .btns-doc-actions {
    display: flex;
    align-items: center;

    .delete-btn {
      font-size: 18px;
      margin-left: 8px;
    }

    .delete-btn:hover {
      color: #c50404;
    }

    .ant-upload,
    .anticon-download {
      font-size: 18px;
    }

    .ant-upload:hover,
    .anticon-download:hover {
      color: var(--green);
      cursor: pointer;
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}